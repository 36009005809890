<template>
  <div class="box">
    <div class="header">
      <div class="header-search" style="margin-bottom: 4px;">
        <van-nav-bar
          title="历史调整记录"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
      <div v-for="(lists, index) in hist" :key="index" style="margin: 10px; padding: 14px; border-radius: 10px; background-color: #ffffff; color: #999999; font-size: 12px; line-height: 18px;">

        <div style="font-weight: 700; line-height: 24px; color: #333333; margin-bottom: 16px; font-size: 14px;">{{lists.agreementname}}</div>

        <div>调整前节约率（%）：{{lists.saveratioOld}}</div>
        <div>调整后节约率（%）：{{lists.saveratio}}</div>
        <div>生效时间：{{lists.effectivedate}}</div>
        <div>提报人：{{lists.subuserName}}</div>
        <div>提报日期：{{lists.subdate}}</div>
        <div>审批通过时间：{{lists.approvalpassdate}}</div>
        <div>备注：{{lists.remark}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hist: []
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    }
  },
  async created() {
    let data = await this.$api.ec.getAgreementlistPart(this.$route.query.agreement)
    this.hist = data.data
  }
}
</script>

<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
  background-color: #ffffff00;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>